<template>
  <Navbar/>
  <div class="container-fluid mt-5">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4 gap-3">
      <h3 class="text-black fw-bold">Список платежів</h3>
      <div class="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-3 w-100">
        <input
            v-model="searchQuery"
            type="text"
            class="form-control flex-grow-1 search-input"
            placeholder="Пошук по Reference або InvoiceID"
        />
        <select v-model="statusFilter" class="form-select category-select">
          <option value="">Всі статуси</option>
          <option value="created">Створено</option>
          <option value="processing">В обробці</option>
          <option value="hold">На утриманні</option>
          <option value="success">Успішно</option>
          <option value="failure">Не успішно</option>
          <option value="reversed">Повернено</option>
          <option value="expired">Прострочено</option>
        </select>
        <div class="date-filters">
          <label for="dateFrom">Від:</label>
          <input type="date" id="dateFrom" v-model="dateFrom" class="form-control" />
          <label for="dateTo">До:</label>
          <input type="date" id="dateTo" v-model="dateTo" class="form-control" />
        </div>
        <button @click="resetFilters" class="btn btn-outline-secondary">Скинути</button>
      </div>
    </div>

    <div class="table-responsive shadow-sm bg-white rounded">
      <table class="table table-hover align-middle">
        <thead class="table-light text-center">
        <tr>
          <th>Reference ID</th>
          <th>Invoice ID</th>
          <th>Instructor ID</th>
          <th>Сума</th>
          <th>Статус</th>
          <th>Дата створення</th>
          <th>Дії</th>
        </tr>
        </thead>
        <tbody class="text-center">
        <tr v-for="payment in paginatedPayments" :key="payment.id">
          <td>{{ payment.reference }}</td>
          <td>{{ payment.invoice_id }}</td>
          <td>{{ payment.instructor }}</td>
          <td>{{ payment.amount }} UAH</td>
          <td>
            <span :class="statusBadgeClass(payment.status)">{{ payment.status }}</span>
          </td>
          <td>{{ formatDate(payment.created_at) }}</td>
          <td class="actions-column">
            <button
                v-if="payment.status !== 'reversed' && payment.status !== 'success'"
                class="btn btn-sm btn-outline-success mb-2"
                @click="handleProcessPayment(payment.invoice_id)"
            >
              Провести оплату
            </button>

            <button
                v-if="payment.status === 'success'"
                class="btn btn-sm btn-outline-danger mb-2"
                @click="handleRefund(payment.invoice_id)"
            >
              Повернення
            </button>

            <button class="btn btn-sm btn-outline-primary mb-2" @click="showDetails(payment)">
              Повна інформація
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <nav aria-label="Page navigation" class="mt-4">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Попередня</a>
        </li>
        <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Наступна</a>
        </li>
      </ul>
    </nav>

    <div v-if="selectedPayment" class="modal show fade" tabindex="-1" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-white text-black">
            <h5 class="modal-title">Деталі платежу</h5>
            <button type="button" class="btn-close" @click="closeModal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-md-6"><strong>Reference ID:</strong> {{ selectedPayment.reference }}</div>
              <div class="col-md-6"><strong>Invoice ID:</strong> {{ selectedPayment.invoice_id }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6"><strong>Instructor ID:</strong> {{ selectedPayment.instructor }}</div>
              <div class="col-md-6"><strong>Сума:</strong> {{ selectedPayment.amount }} UAH</div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6"><strong>Статус:</strong> {{ selectedPayment.status }}</div>
              <div class="col-md-6"><strong>Дата створення:</strong> {{ formatDate(selectedPayment.created_at) }}</div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12">
                <strong>Токен Monobank:</strong>
                <div>
                  <a>{{ selectedPayment.token }}</a>
                </div>
              </div>
              <div class="col-md-12">
                <strong>Посилання на оплату:</strong>
                <div class="payment-link">
                  <a :href="selectedPayment.payment_link" target="_blank">{{ selectedPayment.payment_link }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">Закрити</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAllPayments, refundPayment, successPayment} from '@/js/api';
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar
  },
  data() {
    return {
      payments: [],
      selectedPayment: null,
      searchQuery: '',
      statusFilter: '',
      dateFrom: '',
      dateTo: '',
      currentPage: 1,
      itemsPerPage: 9,
    };
  },
  computed: {
    filteredPayments() {
      let filtered = this.payments;

      if (this.searchQuery) {
        filtered = filtered.filter(
            (payment) =>
                payment.reference.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                payment.invoice_id.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }

      if (this.statusFilter) {
        filtered = filtered.filter((payment) => payment.status === this.statusFilter);
      }

      if (this.dateFrom) {
        filtered = filtered.filter(
            (payment) => new Date(payment.created_at) >= new Date(this.dateFrom)
        );
      }

      if (this.dateTo) {
        filtered = filtered.filter(
            (payment) => new Date(payment.created_at) <= new Date(this.dateTo)
        );
      }

      // Сортировка от новых к старым
      filtered.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      return filtered;
    },
    paginatedPayments() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredPayments.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredPayments.length / this.itemsPerPage);
    },
  },
  created() {
    this.fetchPayments();
  },
  methods: {
    async fetchPayments() {
      try {
        this.payments = await getAllPayments();
      } catch (error) {
        console.error('Помилка при завантаженні платежів:', error);
      }
    },
    async handleRefund(invoiceId) {
      try {
        const result = await refundPayment(invoiceId);
        alert(result.message);
        await this.fetchPayments();
      } catch (error) {
        console.error('Помилка при поверненні коштів:', error.message);
        alert('Помилка при поверненні коштів: ' + error.message);
      }
    },
    async handleProcessPayment(invoiceId) {
      try {
        const result = await successPayment(invoiceId)
        alert(result.message);
        await this.fetchPayments();
      } catch (err) {
        console.error('Помилка пітвердження платежу:', err.message);
        alert('Помилка при пітвердженні платежі: ' + err.message);
      }
    },
    showDetails(payment) {
      this.selectedPayment = payment;
    },
    closeModal() {
      this.selectedPayment = null;
    },
    resetFilters() {
      this.searchQuery = '';
      this.statusFilter = '';
      this.dateFrom = '';
      this.dateTo = '';
      this.currentPage = 1;
    },
    statusBadgeClass(status) {
      switch (status) {
        case 'success':
          return 'badge bg-success';
        case 'failed':
          return 'badge bg-danger';
        case 'pending':
        case 'processing':
        case 'created':
        case 'hold':
          return 'badge bg-warning text-dark';
        case 'reversed':
          return 'badge bg-secondary';
        case 'expired':
          return 'badge bg-dark';
        default:
          return 'badge bg-secondary';
      }
    },
    formatDate(date) {
      const options = {year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};
      return new Date(date).toLocaleDateString('ua-UA', options);
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
};
</script>

<style scoped>
.container-fluid {
  max-width: 1400px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
}

.table-responsive {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.table thead {
  background-color: #f1f1f1;
  color: #495057;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-dialog {
  max-width: 800px;
}

.badge {
  font-size: 0.9em;
  padding: 0.5em 1em;
}

.pagination .page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.pagination .page-link {
  color: #007bff;
}

.pagination .page-link:hover {
  color: #0056b3;
}

.actions-column {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}

.payment-link {
  overflow-wrap: anywhere;
  word-break: break-word;
}

.date-filters {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

@media (min-width: 768px) {
  .container-fluid input.search-input {
    width: 500px;
  }

  .category-select {
    width: 200px;
  }

  .date-filters {
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
</style>
