<template>
  <nav class="navbar navbar-dark bg-dark">
    <div class="container-fluid d-flex justify-content-between">
      <span class="navbar-brand mb-0 h1">
        skischool
      </span>
      <button class="btn btn-outline-light" @click="logout">
        Вихід
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  }
}
</script>

<style>
</style>
