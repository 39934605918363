<template>
  <div class="d-flex justify-content-center align-items-center vh-100 col-lg-12" style="background-color: gray">
    <div class="container p-5 col-lg-4" style="border: 1px solid gray;border-radius: 30px;background-color: #f1f1f1">
        <div class="">
          <div class="text-center">
            <label for="phone" class="form-label"><b>Логін</b></label>
          </div>
          <input
              v-model="login"
              type="text"
              class="form-control text-center"
              id="login"
              placeholder="Введіть свій логін"
              required
          />
        </div>
      <div class="mb-3">
        <div class="text-center">
          <label for="phone" class="form-label"><b>Пароль</b></label>
        </div>
        <input
            v-model="pass"
            type="password"
            class="form-control text-center"
            id="pass"
            placeholder="Введіть пароль"
            required
        />
      </div>
        <div class="text-center">
          <button class="btn btn-primary text-center" @click="goToLogin()">Увійти</button>
        </div>

      <div v-if="errorMessage" class="alert alert-danger mt-3">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import {logIn} from "@/js/api";

export default {
  data() {
    return {
      pass: '',
      login: '',
      errorMessage: ''
    };
  },
  methods: {
    async goToLogin() {
      try {
        const response = await logIn(this.login, this.pass);

        if (response.token) {
          localStorage.setItem('token', response.token);
          this.errorMessage = '';
          this.$router.push('/');
        }
      } catch (err) {
        console.error(err);
        this.errorMessage = err.message;
      }
    }
  }
};
</script>
<style>

</style>
